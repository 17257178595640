/**
***************************************************************************
* NAME 			: ROMICheckSummary
* PROPS	 		: None
* RETURNS 		: Display for ROMICheck on the dashboard
* LAST MODIFIED 	: November 5, 2024
***************************************************************************
*/

// import { useContext } from 'react';
import { Link } from 'react-router-dom';
// import AuthContext from '../../../context/auth-context';
// import NotificationContext from '../../../context/notification-context';

const ROMICheckSummary = () => {
	// const authCtx = useContext(AuthContext);

	return (
		<>
			<article>
				<div className="article_copy">
				<Link to="https://romicheck.medialogic.com/" target="_blank"><img src="/img/romicheck-stack-logo.svg" alt="" /></Link>
				</div>
			</article>
			<p><Link to="https://romicheck.medialogic.com/" className="external" target="_blank">Open</Link></p>
		</>
  	)
}

export default ROMICheckSummary;