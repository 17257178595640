/**
***************************************************************************
* NAME 			: HomeCard
* PROPS	 		: children (component name/label - string)
*				: icon (dynamic portion of the image)
* RETURNS 		: Container for the available component's home card display
* LAST MODIFIED 	: November 5, 2024
***************************************************************************
*/

import CountyAnalysisSummary from '../containers/county-analysis/Summary';
import DataSnapSummary from '../containers/datasnap/Summary';
import HealthcareInsightsSummary from '../containers/healthcare-insights/Summary';
import ConsumerInSightSummary from '../containers/consumer-in-sight/Summary';
import IndustryNewsSummary from '../containers/industry-news/Summary';
import CompetiscanReportsSummary from '../containers/competiscan-reports/Summary';
import StrategicDecksSummary from '../containers/strategic-decks/Summary';
import CampaignReportsSummary from '../containers/campaign-reports/Summary';
import CreativeLibrarySummary from '../containers/creative-library/Summary';
import ROMICheckSummary from '../containers/romicheck/Summary';

const HomeCard = (props) => {
	return (
		<>
			<h2 className="homecard_category_name">
				{props.icon &&
					<img src={'/img/homecard/' + props.icon + '-icn-drk-blue.svg'} alt="" width="28" height="28" />
				}
				{props.children.replace("ML ", "Media Logic ")}
			</h2>
			<div className={
				(
					(props.children == "Opportunity Analysis")
				) ? "homecard_wrapper analysis" :
				(
					(props.children == "DataSnap")
				) ? "homecard_wrapper datasnap" :
				(
					(props.children == "ROMICheck")
				) ? "homecard_wrapper romicheck" :
				"homecard_wrapper"
			}>
				{(props.children == "Opportunity Analysis") &&
					<CountyAnalysisSummary />
				}
				{(props.children == "DataSnap") &&
					<DataSnapSummary />
				}
				{(props.children == "ML Articles & Perspective") &&
					<HealthcareInsightsSummary />
				}
				{(props.children == "ML Research & Reports") &&
					<ConsumerInSightSummary />
				}
				{(props.children == "Industry News") &&
					<IndustryNewsSummary />
				}
				{(props.children == "Competiscan Reports") &&
					<CompetiscanReportsSummary />
				}
				{(props.children == "Strategic Decks") &&
					<StrategicDecksSummary />
				}
				{(props.children == "Campaign Reports") &&
					<CampaignReportsSummary />
				}
				{(props.children == "Creative Library") &&
					<CreativeLibrarySummary />
				}
				{(props.children == "ROMICheck") &&
					<ROMICheckSummary />
				}
			</div>
		</>
	)
}

export default HomeCard;